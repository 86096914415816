const APP_NAME_IIMJOBS = 'iimjobs';
const APP_NAME_HIRIST = 'hirist';
const THEME = 'theme';
const HOME = 'home';
const CATEGORY = 'category';
const LOCATION_BASED = 'location_based';
const EPOCH_ROUND_ONE = 'epoch-round-one';
const EPOCH_ROUND_TWO = 'epoch-round-two';
const EPOCH_ROUND_THREE = 'epoch-round-three';
const EPOCH = 'epoch';
const EPOCH_REGISTER = 'epoch-register';
const EPOCH_TERMS = 'epoch-terms';
const TAG = 'tag';
const APP = 'app';
const NOTFOUND = 'notfound';
const DOWNLOADAPP = 'downloadapp';
const ABOUTUS = 'aboutus';
const REFUND = 'refund';
const PRIVACY = 'privacy';
const COOKIEPOLICY = 'cookiepolicy';
const TERMS = 'terms';
const FAQ = 'faq';
const JOBFEED = 'jobfeed';
const RESET_PASSWORD = 'resetpassword';
const SAVEDJOBS = 'savedjobs';
const RECRUITER_VIEW = 'recruiterview';
const NOTIFICATIONS = 'allnotification';
const JOBAPPLIED = 'jobapplied';
const JOBDETAIL = 'jobdetail';
const APPLIEDJOB = 'appliedjob';
const SEARCH = 'search';
const COOKIE_CKT = '_CK1';
const COOKIE_LASTACT = '_LASTACT';
const LOCATION = 'location';
const BOTUSERAGENTS = [
  'googlebot',
  'Googlebot-Image',
  'Bingbot',
  'Slurp',
  'Google-InspectionTool',
  'Googlebot-News',
  'Googlebot-Video',
  'Storebot-Google',
  'GoogleOther',
  'BingPreview',
  'MicrosoftPreview',
];
const MYJOBFEED = 'My Jobfeed';
const SLUG_SUFFIX = '-jobs';
const REF_REDIRECT_OLD = 'red_old';

const EXPERIENCE_MAPPING: Array<object> = [
  {minexp: 0, maxexp: 30},
  {minexp: 1, maxexp: 3},
  {minexp: 4, maxexp: 6},
  {minexp: 7, maxexp: 10},
  {minexp: 11, maxexp: 15},
  {minexp: 15, maxexp: 100},
];

export {
  APP_NAME_IIMJOBS,
  APP_NAME_HIRIST,
  THEME,
  HOME,
  APP,
  NOTFOUND,
  DOWNLOADAPP,
  CATEGORY,
  LOCATION_BASED,
  JOBFEED,
  SAVEDJOBS,
  RECRUITER_VIEW,
  NOTIFICATIONS,
  JOBAPPLIED,
  TAG,
  JOBDETAIL,
  SEARCH,
  APPLIEDJOB,
  COOKIE_CKT,
  COOKIE_LASTACT,
  BOTUSERAGENTS,
  MYJOBFEED,
  SLUG_SUFFIX,
  EXPERIENCE_MAPPING,
  REF_REDIRECT_OLD,
  RESET_PASSWORD,
  ABOUTUS,
  REFUND,
  PRIVACY,
  COOKIEPOLICY,
  TERMS,
  FAQ,
  LOCATION,
  EPOCH_ROUND_ONE,
  EPOCH,
  EPOCH_REGISTER,
  EPOCH_ROUND_TWO,
  EPOCH_ROUND_THREE,
  EPOCH_TERMS,
};

const ROUTE_JOBFEED = '/jobfeed';
const ROUTE_NOTFOUND = '/notfound';
const ROUTE_SEARCH = '/search';
const ROUTE_HOME = '/';
const ROUTE_JOB_DETAIL = '/j';
const ROUTE_CATEGORY = '/c';
const ROUTE_TAG = '/k';
const ROUTE_FILTER = '/filter';
const ROUTE_JOB_APPLIED = '/job/applied';
const ROUTE_SAVED_JOBS = '/saved-jobs';

export {
  ROUTE_JOBFEED,
  ROUTE_NOTFOUND,
  ROUTE_SEARCH,
  ROUTE_HOME,
  ROUTE_JOB_DETAIL,
  ROUTE_JOB_APPLIED,
  ROUTE_CATEGORY,
  ROUTE_TAG,
  ROUTE_FILTER,
  ROUTE_SAVED_JOBS,
};
