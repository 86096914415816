import common from './layout/common.json';
import featureFlags from './layout/featureFlags.json';
import home from './layout/home.json';
import jobfeed from './layout/jobfeed.json';
import notfound from './layout/notfound.json';
import subscription from './layout/subscription.json';
import epoch from './layout/epoch.json';

export const app = {
  iimjobs: {
    common,
    featureFlags,
    home,
    jobfeed,
    notfound,
    subscription,
    epoch,
  },
};
